<template>
    <div id="Container">
        <div class="etc_modulo_container">
            <img src="../assets/website/global/logo_difunde.svg" alt="Difunde" class="etc_modulo_logo">
            <div class="etc_modulo_descripcion">
                <div class="etc_modulo_title">Guía para docentes y  padres de familia</div>
                <div class="etc_modulo_parrafo">
                    <p>
                        El fenómeno de las noticias falsas y la desinformación puede afectarnos a todos, especialmente a los más jóvenes, quienes debido a la cantidad de tiempo que se mantienen conectados a internet (210 minutos diarios en promedio), podrían estár consumiendo y compartiendo información falsa.
                    </p><br><p>
                        Con el ánimo de propiciar espacios en donde los más jóvenes puedan identificar esta problemática y cómo evitar caer en ella, hemos diseñado esta guía, en donde docentes y padres de familia encontrarán información y actividades prácticas para abordar esta temática en el aula de clase.
                    </p>
                </div>
            </div>
        </div>

        <a href="https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/guia_entretantocuento.pdf" target="_blank"><div class="botonazul borderblue botonarriba">Guía</div></a>
        <div id="container_difunde" class="etc_main_container">
            <div id="guia_container">
                <div id="guia_preview" class="borderblue">
                    <img src="../assets/website/difunde/miniatura.png" alt="Guía docentes y padres" id="guia_imagen">
                </div>
                <div id="guia_descripcion">
                    <a href="https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/guia_entretantocuento.pdf" target="_blank"><div id="guia_titulo">Descarga la guía aquí</div></a>
                    <div id="guia_parrafo">
                        En esta guía encontrarás: qué es la información falsa y sus diferentes formatos en internet, definiciones de los distintos tipos de sesgos cognitivos, talleres para desarrollar con tus estudiantes, buenas prácticas para el consumo de noticias en internet, entre otros.
                    </div>
                    <a href="https://entretantocuento.nyc3.cdn.digitaloceanspaces.com/guia_entretantocuento.pdf" target="_blank"><div id="guia_descarga">Descarga</div></a>
                </div>
            </div>
        </div>
        <div class="botonazul borderblue botonabajo">¿Qué encontrarás?</div>

        <div id="atributos">
            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/difunde/icono_1.svg" class="atributo_logo">
                <div class="atributo_titulo">
                    Contexto
                </div>
                <div class="atributo_parrafo">
                    Identifica porqué las noticias falsas y la desinformación son una problemática urgente a resolver en la actualidad y cuáles son las acciones más efectivas para identificar la información falsa.
                </div>
            </div>

            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/difunde/icono_2.svg" class="atributo_logo">
                <div class="atributo_titulo">
                    Actividades en el aula
                </div>
                <div class="atributo_parrafo">
                    Usa esta sección como una guía de actividades que puedes replicar con tus estudiantes en el aula, con el objetivo de propiciar reflexiones respecto a las Fake News y cómo evitar caer en ellas.
                </div>
            </div>

            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/difunde/icono_3.svg" class="atributo_logo">
                <div class="atributo_titulo">
                    Conceptos clave
                </div>
                <div class="atributo_parrafo">
                    Además de las Fake News, existen otros tipos de información falsa que abundan en internet; en esta sección indentificarás fenómenos como el "click bait", teorías de conspiración, rumores, etc.
                </div>
            </div>

            <div class="atributo_contenedor" data-aos="zoom-in">
                <img src="../assets/website/difunde/icono_4.svg" class="atributo_logo">
                <div class="atributo_titulo">
                    Sesgos cognitivos
                </div>
                <div class="atributo_parrafo">
                    Profundiza tu conocimiento respecto a los sesgos cognitivos, encontrarás una definición de los sesgos más comunes al  momento de consumir información en internet y estrategias para evitarlos.
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#container_difunde {
    height: auto;
}

#guia_container {
    background-color: white;
    max-width: 1000px;
    margin: 0px auto;
    border-radius: 10px;

    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;

    padding: 40px 20px;
    height: 100%;
}
#guia_preview {
    background-color: #54ceea;
    position: relative;
    padding: 15px 30px;
}
#guia_imagen {
    min-width: 150px;
    max-width: 220px;
    height: auto;
    margin: 0px auto;
}
#guia_descripcion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px;
    max-width: 600px;
}
#guia_titulo {
    color: #54ceea;
    font: 36px "Bebas Neue";
}
#guia_parrafo {
    text-align: justify;
    width: 80%;
    padding: 20px;
}
#guia_descarga {
    background-color: #0C1D3E;
    border-radius: 10px;
    border: 2px solid #54ceea;
    color: white;
    font: 28px "Bebas Neue";
    padding: 5px 30px;
    margin-top: 30px;
}
#guia_descarga:hover {
    color: #54ceea;
    transform: scale(1.1);
}

#atributos {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    align-items: center;

    padding: 40px 0px 40px;
    max-width: 1000px;
    margin: 0px auto;
}
.atributo_contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    min-width: 300px;
    margin: 20px;
}
.atributo_logo {
    height: 7vh;
}
.atributo_titulo {
    font: 32px "Bebas Neue";
    color: #54ceea;
    margin-top: 10px;
}
.atributo_parrafo {
    width: 80%;
    padding: 20px;
}

@media screen and (max-width: 820px) {
    #guia_descripcion {
        padding: 40px 0px 0px;
    }
    #guia_parrafo {
        width: 100%;
        padding: 20px 0px;
    }

    .atributo_parrafo {
        width: 80%;
        padding: 20px 0px;
    }
}
</style>
